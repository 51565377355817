import React from "react";
import Auth from "../../../utils/auth";
import { Navigate } from "react-router-dom";
import Nav from "../../../components/Nav";
import { EDIT_MEMBER } from "../gql/mutations";
import { GET_MEMBER } from "../gql/queries";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const Update = () => {
    const { register, handleSubmit, reset } = useForm();

    const { memberId: memberParam } = useParams();
    const { loading, data: userData } = useQuery(GET_MEMBER,
        {
            onCompleted: (data) => reset(data.member),
            variables: { memberId: memberParam }
        }
    );

    const [editMember] = useMutation(EDIT_MEMBER);

    const onSubmit = async (formData, event) => {
        try {
            const { data } = await editMember({
                variables: {
                    memberId: memberParam,
                    fullName: formData.fullName,
                    email: formData.email,
                    its: formData.its,
                },
            });
            reset({
                fullName: "",
                email: "",
                its: "",
            });
            showModal();
        } catch (err) {
            console.error(err);
        }
    };

    const showModal = () => {
        const modal = document.querySelector(".modalParent");
        modal.style.visibility = "visible";
    };

    const closeModal = () => {
        const modal = document.querySelector(".modalParent");
        modal.style.visibility = "hidden";
    };

    return (
        <>
            {!Auth.loggedIn() && <Navigate to="/login" />}
            <div className="navAndHeader">
                <Nav />
            </div>

            <div className="modalParent">
                <div className="modalSuccessful">
                    <h3>Update Successful!</h3>
                    <button className="ok" onClick={closeModal}>
                        <p>Close</p>
                    </button>
                </div>
            </div>

            <div className="mainContainer">
                <h1>Update Member Information</h1>
                <div className="loginForm">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label htmlFor="fullName">Full Name</label>
                        <input {...register("fullName", { required: true })} />
                        <label htmlFor="its">ITS</label>
                        <input {...register("its", { required: true })} />
                        <label htmlFor="email">Email</label>
                        <input {...register("email", {
                            pattern: /^([a-zA-Z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
                            required: true,
                        })} />
                        <div className="loginButton">
                            <button type="submit">
                                <h3>Update</h3>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Update;
