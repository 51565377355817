import React from "react";
import { RESET_PASSWORD } from "../gql/mutations";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

const Reset = () => {
    const { register, handleSubmit, formState: { errors }, reset: formReset } = useForm();
    const [reset] = useMutation(RESET_PASSWORD);

    const onSubmit = async (formData) => {
        try {
            const { data } = await reset({
                variables: {
                    its: formData.its,
                    hofIts: formData.hofIts,
                    password: formData.password,
                },
            });
            formReset({
                its: "",
                hofIts: "",
                password: "",
            });
            showModal();
        } catch (err) {
            console.error(err);
        }
    }

    const showModal = () => {
        const modal = document.querySelector(".modalParent");
        modal.style.visibility = "visible";
    };

    const closeModal = () => {
        const modal = document.querySelector(".modalParent");
        modal.style.visibility = "hidden";
    };
    return (
        <>
            <div className="modalParent">
                <div className="modalSuccessful">
                    <h3>Password successfully updated!</h3>
                    <button className="ok" onClick={closeModal}>
                        <p>Close</p>
                    </button>
                </div>
            </div>
            <div className='mainContainer'>
                <div className='logo' />
                <div className='loginForm resetForm'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label htmlFor='its'>ITS</label>
                        <input {...register("its", { required: true })} />
                        {errors.its && <p>This field is required</p>}

                        <label htmlFor='hofIts'>HOF ITS</label>
                        <input {...register("hofIts", { required: true })} />
                        {errors.hofIts && <p>This field is required</p>}

                        <label htmlFor='password'>Enter new password</label>
                        <input type="password" {...register("password", { required: true })}
                        />
                        {errors.password && <p>This field is required</p>}

                        <div className='loginButton'>
                            <button type="submit"><h3>Reset</h3></button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
};

export default Reset;