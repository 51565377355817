import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { timeConverter } from "../../../utils/timeConverter";

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        fontSize: 15,
        flexDirection: "column",
    },
    section: {
        margin: 20,
        flexGrow: 1
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
    },
    description: {
        width: "50%",
        border: "1px solid black",
    },
    rowDataPadding: {
        paddingLeft: '10',
        paddingVertical: '2'
    },
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginHorizontal: '20'
    },
    headerText: {
        width: "50%",
        padding: '3'
    },
    titleText: {
        textAlign: "center",
        paddingVertical: '2'
    }
});

const ListPdfDocument = (props) => {
    return (
        <>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.tableContainer}>
                        <View style={styles.row}>
                            <Text style={styles.headerText}>{props.signupsForMenuItem[0].menuItem.dish.dishName}</Text>
                            <Text style={styles.headerText}>{timeConverter(props.signupsForMenuItem[0].menuItem.menuDate)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.headerText}></Text>
                            <Text style={styles.headerText}>Large: {props.signupsForMenuItem.filter(signup => signup.size === "Large").length}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.headerText}></Text>
                            <Text style={styles.headerText}>Small: {props.signupsForMenuItem.filter(signup => signup.size === "Small").length}</Text>
                        </View>
                    </View>
                    <View style={styles.tableContainer}>
                        <Text style={[styles.description, styles.titleText]}>Name</Text>
                        <Text style={[styles.description, styles.titleText]}>Size</Text>
                    </View>
                    {
                        props.signupsForMenuItem && props.signupsForMenuItem.map(
                            entry => {
                                return (
                                    <View style={styles.tableContainer}>
                                        <View style={styles.row}>
                                            <Text style={[styles.description, styles.rowDataPadding]}>{entry.user.fullName}</Text>
                                            <Text style={[styles.description, styles.rowDataPadding]}>{entry.size}</Text>
                                        </View>
                                    </View>
                                )
                            }
                        )
                    }
                </Page>
            </Document>
        </>
    );
}

export default ListPdfDocument;