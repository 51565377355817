import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_COOKS_MENU_ITEMS_BY_DATE } from "../gql/queries";
import SectionHeader from "../../../components/SectionHeader";
import { timeConverter } from "../../../utils/timeConverter";

const UpcomingForCook = () => {

    const { cookId: cookParam } = useParams();

    const { data: cookMenuItemsData } = useQuery(GET_COOKS_MENU_ITEMS_BY_DATE, { variables: { cookId: cookParam } });

    const cookMenuItems = cookMenuItemsData?.cookMenuItemsByDate;

    return (
        <>
            <SectionHeader title="Upcoming Dishes" className='sectionHeader' />
            <div className="dishContainer">
                {cookMenuItems && cookMenuItems.map((cookMenu) => (
                    <>
                        <div id="cookDishesRow" className="dishesRow">
                            <p>{cookMenu.dish?.dishName}</p>
                            <p>{timeConverter(cookMenu.menuDate)}</p>
                        </div>
                        <hr className="dishRowLine" />
                    </>
                ))}
            </div>
        </>
    );
}

export default UpcomingForCook;
