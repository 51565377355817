import {gql} from "@apollo/client";

export const ADD_DISH = gql`
    mutation addDish($dishName: String! $category: String) {
        addDish(dishName: $dishName category: $category) {
            dishName
            category
        }
    }
`

export const UPDATE_DISH_AND_UPLOAD_IMAGE = gql`
    mutation updateDishWithImage($dishPhoto: String!, $dishId: ID) {
        updateDishWithImage(dishPhoto: $dishPhoto, dishId: $dishId) {
            dishPhoto
            _id
        }
    }
`
