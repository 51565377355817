import {gql} from "@apollo/client";

export const GET_COOKS_MENU_ITEMS_BY_DISH = gql`
  query cookMenuItemsByDish($cookId: ID!, $dishId: ID!) {
    cookMenuItemsByDish(cookId: $cookId, dishId: $dishId) {
      _id
      cook {
        _id
        fullName
      }
      dish {
        _id
        dishName
      }
      menuDate
      amount
      isPaid
    }
  }
`

export const GET_COOKS_UNPAID_MENUS = gql`
  query getCooksUnpaidMenus($cookId: ID!) {
    getCooksUnpaidMenus(cookId: $cookId) {
      _id
      cook {
        _id
        fullName
      }
      dish {
        _id
        dishName
        dishPhoto
      }
      menuDate
      amount
    }
  }
`

export const GET_COOKS_MENU_ITEMS_BY_DATE = gql`
  query getCooksMenuItemsByDate($cookId: ID!) {
    cookMenuItemsByDate(cookId: $cookId) {
      _id
      cook {
        _id
        fullName
      }
      dish {
        _id
        dishName
        dishPhoto
      }
      menuDate
      amount
    }
  }
`

export const GET_MENU_ITEMS_BY_COOK_NAME = gql`
  query menuItemsByCookName($fullName: String!) {
    menuItemsByCookName(fullName: $fullName) {
      _id
      cook {
        _id
        fullName
      }
      dish {
        _id
        dishName
        dishPhoto
      }
      menuDate
      amount
    }
  }
`

export const GET_ALL_COOKS = gql`
  query getAllCooks {
    cooks {
      _id
      fullName
    }
  }
`

export const GET_SINGLE_COOK = gql`
  query getSingleCook($cookId: ID!) {
    cook(cookId: $cookId) {
      _id
      fullName
    }
  }
`
