import React from "react";
import DishPhotoCard from "../../dish/components/DishPhotoCard";
import { timeConverter } from "../../../utils/timeConverter";
import CreateRsvpForm from "./CreateRsvpForm";

const MiqaatCard = ({ miqaat }) => {

    const showModal = () => {
        const modal = document.querySelector('.rsvpModalParent')
        modal.style.visibility = 'visible'
    };

    const closeModal = () => {
        const modal = document.querySelector('.rsvpModalParent')
        modal.style.visibility = 'hidden'
    }

    return (
        <>
            <div className="rsvpModalParent">
                <div className="modalSuccessful">
                    <h3>RSVP Successful!</h3>
                    <button className='ok' onClick={closeModal}><p>Close</p></button>
                </div>
            </div>
            <div className="miqaatRow">
                <div className="miqaatTitle">
                    <p><b>{miqaat.title}</b></p>
                </div>
                {
                    miqaat.image &&
                    <>
                        <DishPhotoCard image={miqaat.image}
                            dish={miqaat}
                            style={{ paddingBottom: '0px' }}
                            radius={"0"}
                            height={"224"}
                            width={"362"}
                        />
                    </>
                }
                <p><b>{miqaat.hijriDate}</b></p>
                <p><b>{timeConverter(miqaat.date)}, {miqaat.time}</b></p>
                <p><b>{miqaat.program}</b></p>
                <hr className="miqaatRowLine" />
                <div style={{ height: "30%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p style={{ textAlign: "center", padding: "5px", fontSize: "14px", lineHeight: "1.5rem" }}>
                        {miqaat.description}
                    </p>
                </div>
                <CreateRsvpForm miqaat={miqaat} showModal={showModal} />
                <hr className="miqaatRowLine" />
                <div className="hostsDiv">
                    <p style={{ fontSize: "14px" }}>
                        Hosts: {miqaat.hosts.map(host => host.fullName).join(", ")}
                    </p>
                </div>
            </div>
        </>
    )
}

export default MiqaatCard;
