import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import SectionHeader from "../../../components/SectionHeader";
import CreateMenuForm from "../../signups/components/CreateMenuForm";
import { timeConverter } from "../../../utils/timeConverter";
import CountInfo from "../../signups/components/CountInfo";
import { GET_DISHES_SINCE_THREE_WEEKS_AGO } from "../gql/queries";

const UpcomingDishList = () => {

    const [dishesSinceThreeWeeksAgo, setDishesSinceThreeWeeksAgo] = useState([]);

    const { loading: dishesLoading, data: dishData } = useQuery(GET_DISHES_SINCE_THREE_WEEKS_AGO);
    let dishesData = dishData?.dishesSinceThreeWeeksAgo || [];

    useEffect(() => {
        if (!dishesLoading) {
            setDishesSinceThreeWeeksAgo(dishesData);
        }
    });

    return (
        <div className="mainContainer">
            <CreateMenuForm dishesSinceThreeWeeksAgo={dishesSinceThreeWeeksAgo} setThisWeeksDishes={setDishesSinceThreeWeeksAgo} />
            <SectionHeader title="Upcoming Dishes" className='sectionHeader' />
            <div className="dishContainer">
                {dishesSinceThreeWeeksAgo && dishesSinceThreeWeeksAgo.map(menu => (
                    <div>
                        <div className="weeklyDishContainer">
                            <div id="upcomingDishesRow" className="dishesRow">
                                <p>{menu.dish?.dishName}</p>
                                <p>{timeConverter(menu.menuDate)}</p>
                                <p>{menu.cook?.fullName}</p>
                                <CountInfo menu={menu} showAll={true} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default UpcomingDishList;
