import {gql} from "@apollo/client";

export const ADD_MEMBER = gql`
    mutation addMember($fullName: String!, $email: String!, $password: String!, $its: String!, $hofIts: String!) {
        addMember(fullName: $fullName, email: $email, password: $password, its: $its, hofIts: $hofIts) {
            token
            me {
                userId
                userFullName
                memberFullName
                memberEmail
                memberIts
                memberHof
                roles
            }
        }
    }    
`

export const EDIT_MEMBER = gql`
    mutation editMember($memberId: ID!, $fullName: String!, $email: String!,  $its: String!) {
    editMember(memberId: $memberId, fullName: $fullName, email: $email, its: $its) {
        fullName
        email
        its
        }
}
`

export const RESET_PASSWORD = gql`
    mutation resetPassword($its: String!, $hofIts: String!, $password: String!) {
        resetPassword(its: $its, hofIts: $hofIts, password: $password) {
            its
            hofIts
        }
    }
`

export const LOGIN_USER = gql`
    mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            token
            me {
                userId
                userFullName
                memberFullName
                memberEmail
                memberIts
                memberHof
                roles
            }
        }
    }
`
