import SectionHeader from "../../../components/SectionHeader";
import { timeConverter } from "../../../utils/timeConverter";
import React from "react";
import { useQuery } from "@apollo/client";
import { GET_RSVP_FOR_USER } from "../gql/queries";

const UserRsvp = () => {

    const { data: usersRsvpsData } = useQuery(GET_RSVP_FOR_USER);
    const usersRsvps = usersRsvpsData?.usersRsvps || [];

    let currentDate = new Date();
    let yesterday = currentDate.setDate(currentDate.getDate()-1).toString();
    let sortByMiqaat = (rsvps) => {
        let rsvpList = [...rsvps];
        return rsvpList.sort((a, b) => a.miqaat.date - b.miqaat.date)
            .filter(rsvp => rsvp.miqaat.date > yesterday)
    }
    let isAnyRsvpForUpcoming = (rsvps) => {
        let rsvpsUpcoming = rsvps.filter(rsvp => rsvp.miqaat.date > yesterday)
        return rsvpsUpcoming.length > 0;
    }
    return (
        <>
            <SectionHeader title="Your Rsvps" className='sectionHeader' />
            <div className="dishContainer">
                {
                    usersRsvps && isAnyRsvpForUpcoming(usersRsvps) ? sortByMiqaat(usersRsvps).map((rsvp) => (
                        <div>
                            <div id="upcomingDishesRow" className="dishesRow">
                                <p className="miqaatText">{rsvp.miqaat.title}</p>
                                <p>{timeConverter(rsvp.miqaat.date)}, {rsvp.miqaat.time}</p>
                                <p>Adults: {rsvp.adults}</p>
                                <p>Children: {rsvp.children}</p>
                            </div>
                            <hr className="dishRowLine" />
                        </div>
                    )) : <p className="errMsg" style={{ textAlign: "center", fontSize: "20px", paddingBottom: "20px" }}>
                        No RSVPs for upcoming miqaats
                    </p>
                }
            </div>
        </>
    )
}

export default UserRsvp;
