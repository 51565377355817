import {gql} from "@apollo/client";

export const GET_ALL_DISHES = gql`
  query getAllDishes {
    dishes {
      _id
      dishName
      category
      dishPhoto
    }
  }
`

export const GET_DISHES_SINCE_THREE_WEEKS_AGO = gql`
  query getDishesSinceThreeWeeksAgo {
    dishesSinceThreeWeeksAgo {
      _id
      cook {
        _id
        fullName
      }
      dish {
        _id
        dishName
        dishPhoto
      }
      menuDate
      amount
    }
  }
`;

