import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import SectionHeader from "../../../components/SectionHeader";
import { GET_ALL_COOKS } from "../../cook/gql/queries";
import { GET_ALL_DISHES } from "../../dish/gql/queries";
import { CREATE_MENU } from "../gql/mutations";

const CreateMenuForm = ({ dishesSinceThreeWeeksAgo, setDishesSinceThreeWeeksAgo }) => {

    const { register, handleSubmit, reset } = useForm();

    const { data: getCookData } = useQuery(GET_ALL_COOKS);
    let cooks = getCookData?.cooks || [];

    const { data: getDishData } = useQuery(GET_ALL_DISHES);
    let dishes = getDishData?.dishes || [];

    const [createMenu] = useMutation(CREATE_MENU);

    let today = new Date().toISOString().split('T')[0];

    const onSubmit = async (newMenuItemData) => {
        let cook = JSON.parse(newMenuItemData.cook);
        let dish = JSON.parse(newMenuItemData.dish);
        try {
            const { data } = await createMenu({
                variables: {
                    dish: dish._id,
                    cook: cook._id,
                    menuDate: newMenuItemData.menuDate,
                    isPaid: false
                },
            });
            let newMenuItem = {
                _id: data._id,
                cook: cook,
                dish: dish,
                menuDate: new Date(newMenuItemData.menuDate).getTime()
            }
            dishesSinceThreeWeeksAgo = [...dishesSinceThreeWeeksAgo, newMenuItem];
            setDishesSinceThreeWeeksAgo(dishesSinceThreeWeeksAgo);
            reset({
                dish: '',
                cook: '',
                menuDate: ''
            })
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            <SectionHeader title="Create Menu" className='sectionHeader' />
            <form onSubmit={handleSubmit(onSubmit)} className="createMenuForm" >
                <select {...register("dish", { required: true })} placeholder="Dish">
                    <option disabled selected value=''>-- Choose a Dish --</option>
                    {dishes && dishes.map((dish) => (
                        <option key={dish._id} value={JSON.stringify(dish)}>{dish.dishName}</option>
                    ))}
                </select>
                <select {...register("cook", { required: true })}>
                    <option disabled selected value=''>-- Choose a Caterer --</option>
                    {cooks && cooks.map((cook) => (
                        <option key={cook._id} value={JSON.stringify(cook)}>{cook.fullName}</option>
                    ))}
                </select>
                <input type="date" format="yyyy-MM-dd" min={today} {...register("menuDate")} />
                <input type='submit' value='Create' />
            </form>
        </>
    )
};

export default CreateMenuForm;