import {gql} from "@apollo/client";

export const GET_FEEDBACK_FOR_COOKS_MENU_ITEMS_BY_DISH = gql`
  query feedbackForCooksMenuItemsByDish($cookId: ID!, $dishId: ID!) {
    feedbackForCooksMenuItemsByDish(cookId: $cookId, dishId: $dishId) {
      _id
      feedbackCount
      dryRating_Avg
      spicyRating_Avg
      oilyRating_Avg
      quantityRating_Avg
      frequencyRating_Avg
      feedbackComments
    }
  }
`

export const GET_FEEDBACK = gql`
  query getFeedback($menuId: ID!) {
    getFeedback(menuId: $menuId) {
      _id
      feedbackCount
    dryRating_Avg
    spicyRating_Avg
    oilyRating_Avg
    quantityRating_Avg
    frequencyRating_Avg
    }
  }
`
