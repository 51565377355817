import React from "react";
import { CREATE_MIQAAT } from "../gql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { GET_ALL_USERS } from "../../user/gql/queries";
import SectionHeader from "../../../components/SectionHeader";

const CreateMiqaat = () => {
    const { register, handleSubmit, reset } = useForm();

    const { data: userData } = useQuery(GET_ALL_USERS);
    let users = userData?.users || [];

    const [createMiqaat] = useMutation(CREATE_MIQAAT);

    const onSubmit = async (formData) => {
        try {
            let hosts = [formData.host1, formData.host2, formData.host3, formData.host4].filter(host => host !== '')
            const { data } = await createMiqaat({
                variables: {
                    title: formData.title,
                    image: formData.image,
                    description: formData.description,
                    date: formData.date,
                    hijriDate: formData.hijriDate,
                    time: formData.time,
                    program: formData.program,
                    hosts: hosts
                },
            });
            reset({
                title: "",
                image: "",
                description: "",
                date: "",
                hijriDate: "",
                time: "",
                program: ""
            });
            showModal();
        } catch (err) {
            console.error(err);
        }
    };

    const showModal = () => {
        const modal = document.querySelector(".modalParent");
        modal.style.visibility = "visible";
    };

    const closeModal = () => {
        const modal = document.querySelector(".modalParent");
        modal.style.visibility = "hidden";
    };

    return (
        <>
            <div className="modalParent">
                <div className="modalSuccessful">
                    <h3>Created Successfully!</h3>
                    <button className="ok" onClick={closeModal}>
                        <p>Close</p>
                    </button>
                </div>
            </div>
            <SectionHeader title="Create Miqaat" className='sectionHeader' />
            <div className="createMenuForm">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div>
                            <label htmlFor="title">Title</label>
                            <input {...register("title", { required: true })} />
                            <label htmlFor="description">Description</label>
                            <input {...register("description", { required: true })} />
                            <label htmlFor="hijriDate">Hijri Date</label>
                            <input {...register("hijriDate", { required: true })} />
                        </div>
                        <div>
                            <label htmlFor="date">Date</label>
                            <input type="date" format="yyyy-MM-dd" {...register("date")} />
                            <label htmlFor="time">Time</label>
                            <input {...register("time")} />
                            <label htmlFor="program">Program</label>
                            <input {...register("program")} />
                        </div>
                    </div>
                    <div>
                        <label>Host 1</label>
                        <select {...register("host1", { required: true })} placeholder="Host 1">
                            <option disabled selected value=''>Choose Hosts</option>
                            {users && users.map((user) => (
                                <option key={user._id} value={user._id}>{user.fullName}</option>
                            ))}
                        </select>
                        <label>Host 2</label>
                        <select {...register("host2")} placeholder="Host 2">
                            <option disabled selected value=''>Choose Hosts</option>
                            {users && users.map((user) => (
                                <option key={user._id} value={user._id}>{user.fullName}</option>
                            ))}
                        </select>
                        <label>Host 3</label>
                        <select {...register("host3")} placeholder="Host 3">
                            <option disabled selected value=''>Choose Hosts</option>
                            {users && users.map((user) => (
                                <option key={user._id} value={user._id}>{user.fullName}</option>
                            ))}
                        </select>
                        <label>Host 4</label>
                        <select {...register("host4")} placeholder="Host 4">
                            <option disabled selected value=''>Choose Hosts</option>
                            {users && users.map((user) => (
                                <option key={user._id} value={user._id}>{user.fullName}</option>
                            ))}
                        </select>
                    </div>
                    <div className="loginButton miqaatCreate">
                        <button type="submit">
                            <h3>Create</h3>
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
};

export default CreateMiqaat;