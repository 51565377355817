import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Auth from '../../../utils/auth'
import { ADD_MEMBER } from "../../user/gql/mutations";

const SignupForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [signup] = useMutation(ADD_MEMBER);
    const [isHover, setIsHover] = useState(false);
    const [activeTab, setActiveTab] = useState("signup");
    const navigate = useNavigate();

    const onSubmit = async (formData) => {
        try {
            const { data } = await signup({
                variables: {
                    email: formData.email.toLowerCase(),
                    fullName: formData.fullName,
                    password: formData.password,
                    its: formData.its,
                    hofIts: formData.hofIts,
                },
            });

            Auth.login(data.addMember.token);
            navigate('/openMenus');
        } catch (err) {
            let errorMessage = document.querySelector('.errMsg')
            errorMessage.innerHTML = err.message
        }
    }

    return (
        <>
            <div className='loginForm'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor='fullName'>Full Name</label>
                    <input {...register("fullName", { required: true })} />
                    {errors.fullName && <p>This field is required</p>}

                    <label htmlFor='its'>ITS</label>
                    <input {...register("its", { required: true })} />
                    {errors.its && <p>This field is required</p>}

                    <label htmlFor='hofIts'>HOF ITS</label>
                    <input {...register("hofIts", { required: true })} />
                    {errors.hofIts && <p>This field is required</p>}

                    <label htmlFor='email'>Email</label>
                    <input
                        {...register("email", {
                            pattern: /^([a-zA-Z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
                            required: true,
                        })}

                    />
                    {errors.email && <p>This field is required</p>}

                    <label htmlFor='password'>Password</label>
                    <input

                        type="password"
                        {...register("password", { required: true })}

                    />
                    {errors.password && <p>This field is required</p>}

                    <div className="errorBox">
                        <p className='errMsg'></p>
                    </div>

                    <div className='loginButton'>
                        <button
                            type="submit"
                            onMouseEnter={() => setIsHover(true)}
                            onMouseLeave={() => setIsHover(false)}
                        >
                            <h3>Sign Up</h3>
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default SignupForm;
