import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
Chart.register(CategoryScale);

// import "./styles.css";

const VerticalBarChart = (props) => {
    const data = {
        labels: ["Spice", "Oil", "Dryness", "Quantity", "Frequency"],
        datasets: [
            {
                label: "First set",
                data: [props.feedback?.spicyRating_Avg, props.feedback?.oilyRating_Avg, props.feedback?.dryRating_Avg, props.feedback?.quantityRating_Avg, props.feedback?.frequencyRating_Avg],
                backgroundColor: "#D9AA16"
            }
        ]
    };

    const options = {
        responsive: false,
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: true,
                        drawBorder: false,
                        borderDash: [3, 3],
                        zeroLineColor: "#D9AA16"
                    },
                    categoryPercentage: 0.7,
                    barPercentage: 0.9,
                    ticks: {
                        beginAtZero: true
                    }
                }
            ],
            yAxes: [
                {
                    display: false,
                    gridLines: {
                        display: false,
                        zeroLineColor: "transparent"
                    },
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        }
    };
    return (
        <Bar width="200" height="200" data={data} options={options} />
    );
}

export default VerticalBarChart;