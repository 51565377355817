import React from "react";
import { useQuery } from "@apollo/client";
import SectionHeader from "../../components/SectionHeader";
import { GET_MENU_ITEMS_BY_COOK_NAME } from "./gql/queries";
import { useParams } from "react-router-dom";
import { timeConverter } from "../../utils/timeConverter";
import CountInfo from "../signups/components/CountInfo";

const CookLogin = () => {
    const { fullName: cookParam } = useParams();
    const { data: cookMenuItemsData } = useQuery(GET_MENU_ITEMS_BY_COOK_NAME, { variables: { fullName: cookParam } });
    const cookMenuItems = cookMenuItemsData?.menuItemsByCookName || [];
    const cook = cookMenuItemsData?.menuItemsByCookName[0]?.cook || {};

    return (
        <>
            <h1>{cook.fullName}</h1>
            <div className="mainContainer">
                <SectionHeader title="Upcoming Dishes" className='sectionHeader' />
                <div className="dishContainer">
                    {cookMenuItems && cookMenuItems.map((cookMenu) => (
                        <>
                            <div id="cookLoginDishesRow" className="dishesRow">
                                <p>{cookMenu.dish?.dishName}</p>
                                <p>{timeConverter(cookMenu.menuDate)}</p>
                                <CountInfo menu={cookMenu} />
                            </div>
                            <hr className="dishRowLine" />
                        </>
                    ))}
                </div>
            </div>
        </>
    )
}

export default CookLogin;