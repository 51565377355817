import React, { useEffect, useState } from "react";
import BarChart from "./component/BarChart";
import VerticalBarChart from "./component/VerticalBarChart";
import { useQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import Nav from "../../components/Nav";
import Auth from "../../utils/auth";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader";
import { GET_FEEDBACK } from "./gql/queries";
import { PROVIDE_FEEDBACK } from "./gql/mutations";
import { GET_ME } from "../user/gql/queries";
import {useMediaQuery} from "../../utils/deviceChecker";

const Feedback = () => {
  const [provideFeedback] = useMutation(PROVIDE_FEEDBACK);
  const { register, handleSubmit, reset } = useForm();

  const { menuId: menuParam } = useParams();

  const { data } = useQuery(GET_ME);
  const me = data?.me;

  const { loading: feedbackLoading, data: feedbackData } = useQuery(
    GET_FEEDBACK,
    { variables: { menuId: menuParam } }
  );

  const feedback = feedbackData?.getFeedback || [];

  const showFeedbackResults = () => {
    const feedbackResultsHeader = document.querySelector(
      ".feedbackSectionHeader"
    );
    feedbackResultsHeader.style.display = "block";
    const feedbackResults = document.querySelector(".feedbackContainer");
    feedbackResults.style.display = "flex";
    const feedbackForm = document.querySelector(".feedbackFormContainer");
    feedbackForm.style.display = "none";
    const feedbackFormHeader = document.querySelector(".feedbackFormHeader");
    feedbackFormHeader.style.display = "none";
  };

  const onSubmit = async (feedbackData) => {
    try {
      const { data } = await provideFeedback({
        variables: {
          menuItem: menuParam,
          user: me.userId,
          spicyRating: parseInt(feedbackData.spice),
          oilyRating: parseInt(feedbackData.oil),
          quantityRating: parseInt(feedbackData.quantity),
          frequencyRating: parseInt(feedbackData.frequency),
          dryRating: parseInt(feedbackData.dryness),
          feedbackComments: feedbackData.feedbackComments
        },
      });
      showFeedbackResults();
    } catch (err) {
      console.error(err);
    }
  };

  const isDesktop = useMediaQuery("(min-width: 1258px)");

  const resultsMessage = (count) => {
    if (count === 0 || count === undefined) {
      return "You are the first to provide feedback!";
    } else {
      return "Showing average from " + count + " result" + (count === 1 ? "" : "s");
    }
  };
  const feedbackScale = [1, 2, 3, 4, 5];
  const feedbackCriteria = ["Spice", "Oil", "Dryness", "Quantity", "Frequency"];
  return (
    <>
      {!Auth.loggedIn() && <Navigate to="/login" />}
      <div className="navAndHeader">
        <Nav />
      </div>
      <h1>Feedback</h1>
      <div className="mainContainer">
        <SectionHeader
          title="Feedback Form"
          className="sectionHeader feedbackFormHeader"
        />
        <div className="dishContainer feedbackFormContainer">
          <p>Submit your feedback to see all results</p>

          <div className="grid-container">
            <div className="grid-item blank"></div>
            <div className="grid-item too-much">
              <h4>Could be more</h4>
            </div>
            <div className="grid-item just-right">
              <h4>Just right</h4>
            </div>
            <div className="grid-item too-much">
              <h4>Too much</h4>
            </div>
          </div>

          <div class='mobileFeedbackLabels feedbackInputs'>
            <p>Submit your feedback to see all results</p>
            <h4>1-2: Could be more</h4>
            <h4>3: Just right</h4>
            <h4>4-5: Too much</h4>
          </div>

          <form className="feedbackForm" onSubmit={handleSubmit(onSubmit)}>
            {feedbackCriteria.map((criteria) => {
              return (
                <div className="feedbackInputs">
                  <legend>{criteria}</legend>
                  <div className="ratingRadioButtons">
                    {feedbackScale.map((rating) => {
                      return (
                        <div>
                          <input
                            type="radio"
                            value={rating.toString()}
                            {...register(criteria.toLowerCase())}
                          />
                          <label htmlFor={rating.toString()}>{rating}</label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div className="feedbackCommentsContainer">
              <label htmlFor="Additional Comments"><h4>Additional Comments</h4></label>
              <input type="text" {...register("feedbackComments")} />
            </div>
            <input type="submit" value="Submit" />
          </form>
        </div>
        <SectionHeader
          title="Feedback Results"
          className="feedbackSectionHeader"
        />
        <div className="feedbackContainer">
          <h4>{resultsMessage(feedback[0]?.feedbackCount)}</h4>
          {isDesktop ? (
            <BarChart feedback={feedback[0]} />
          ) : (
            <VerticalBarChart feedback={feedback[0]} />
          )}
        </div>
      </div>
    </>
  );
};

export default Feedback;
