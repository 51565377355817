import { gql } from "@apollo/client";

export const GET_ALL_MIQAATS = gql`
query getAllMiqaats {
  miqaats {
    _id
    title
    description
    image
    hijriDate
    date
    program
    time
    hosts {
      _id
      fullName
    } 
  }
}
`

export const GET_MIQAATS_FOR_USER = gql`
  query getUserMiqaats {
    userMiqaats {
      date
      hijriDate
      description
      title
      _id
      time
    }
  }
`

export const GET_RSVP_FOR_MIQAAT = gql`
  query getRsvps($miqaatId: ID!) {
    rsvps(miqaatId: $miqaatId) {
      miqaat
      miqaatDate
      miqaatId
      adultCount
      childrenCount
      users {
        fullName
        adults
        children
      }
    }
  }
`

export const GET_RSVP_FOR_USER = gql`
  query usersRsvps {
    usersRsvps {
       _id
    adults
    children
    miqaat {
      title
      _id
      date
      time
    }
    }
  }
`

export const GET_USER_RSVP_FOR_MIQAAT = gql`
  query usersRsvpsForMiqaat($miqaatId: ID!) {
    usersRsvpsForMiqaat(miqaatId: $miqaatId) {
       _id
    adults
    children
    miqaat {
      title
      _id
      date
      time
    }
    }
  }
`
