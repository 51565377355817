import {gql} from "@apollo/client";

export const GET_ALL_MENUS = gql`
  query getAllMenus {
    menus {
      _id
      cook {
        _id
        fullName
      }
      dish {
        _id
        dishName
        dishPhoto
      }
      menuDate
    }
  }
`

export const GET_ALL_SIGNUPS = gql`
  query getAllSignups {
    signups {
      user {
        _id
        fullName
        zone
      }
      menuItem {
        _id
        dish {
          dishName
          dishPhoto
        }
        menuDate
      }
      size
    }
  }
`

export const GET_USER_SIGNUPS = gql`
  query getUserSignups {
    userSignups {
      _id
      user {
        fullName
      }
      menuItem {
        _id
        dish {
          dishName
          dishPhoto
        }
        menuDate
      }
      size
    }
  }
`

export const GET_OPEN_MENUS_ON_CLICK = gql`
  query getOpenMenusOnClick($number: String) {
    openMenusOnClick(number: $number) {
      _id
      cook {
        _id
        fullName
      }
      dish {
        _id
        dishName
        dishPhoto
      }
      menuDate
      amount
    }
  }
`

export const GET_USER_SIGNUPS_ON_CLICK = gql`
  query getUserSignupsOnClick($number: String) {
    userSignupsOnClick(number: $number) {
       _id
      user {
        fullName
      }
      menuItem {
        _id
        dish {
          dishName
          dishPhoto
        }
        menuDate
      }
      size
    }
  }
`

export const GET_SIGNUPS_FOR_MENU_ITEM = gql`
  query getSignupsForMenuItem($menuId: ID!) {
    getSignupsForMenuItem(menuId: $menuId) {
      menuItem {
        _id
        cook {
          fullName
        }
        dish {
          dishName
        }
        menuDate
      }
      size
      user {
        _id
        fullName
        zone
      }
    }
  }
`