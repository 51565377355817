import React from "react";
import { useLazyQuery } from "@apollo/client";
import { CSVLink } from "react-csv";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { isDateMoreThanXDaysFromToday, DEADLINE_DAYS } from "../../../utils/timeConverter";
import ListPdfDocument from "./ListPdfDocument";
import { GET_SIGNUPS_FOR_MENU_ITEM } from "../gql/queries";

const CountInfo = ({ menu, showAll, pickupGroup }) => {

    const [getSignupsForMenu, { loading, data: getPdfListData }] = useLazyQuery(GET_SIGNUPS_FOR_MENU_ITEM, { variables: { menuId: "" } });

    const showGenerateButtons = () => {
        let allGenerateDivs = document.getElementsByClassName("generateDiv");
        if (allGenerateDivs.length > 0) {
            for (let i = 0; i < allGenerateDivs.length; i++) {
                allGenerateDivs[i].style.display = "none";
            }
        }
        let allGenerateBtns = document.getElementsByClassName("generateBtn");
        if (allGenerateBtns.length > 0) {
            for (let i = 0; i < allGenerateBtns.length; i++) {
                allGenerateBtns[i].style.display = "block";
            }
        }
    }

    const fetchMenuList = async (event) => {
        showGenerateButtons();
        let id = event.target.id;
        const generateButton = document.querySelector(`#button${id}`)
        generateButton.style.display = "none";
        const generateDiv = document.querySelector(`#countAndLinkInfo${id}`);
        generateDiv.style.display = 'flex'
        generateDiv.className = 'generateDiv'
        await getSignupsForMenu({
            variables: { menuId: id }
        });
    };

    const isShowButtons = (data, buttonId) => {
        if (data === null) {
            console.log("No data found for list generation");
            return false;
        }
        if (data.getSignupsForMenuItem === undefined || data.getSignupsForMenuItem.length === 0) {
            console.log("No data found for list generation");
            return false;
        }
        return data.getSignupsForMenuItem[0].menuItem._id === buttonId;
    };

    const fileNameGenerator = (menu) => {
        let epoch = parseFloat(menu.menuDate);
        return menu.dish.dishName.replace(/ /g, '') + "-" + new Date(epoch).toLocaleDateString('en-US');
    }

    const getCsvHeaders = () => {
        return [
            { label: "Name", key: "name" },
            { label: "Day", key: "day" },
            { label: "Size", key: "size" },
            { label: "Zone", key: "zone" },
            { label: "Dish", key: "dish" },
        ];
    }

    const convertToCsvDataForAvery = (signupData, pickupGroup) => {
        if (pickupGroup) {
            const userIds = [];
            pickupGroup.users.forEach(user => userIds.push(user._id));
            return signupData
                .filter(signup => userIds.includes(signup.user._id))
                .map(signup => {
                    return {
                        name: signup.user.fullName,
                        day: new Date(parseFloat(signup.menuItem.menuDate)).toLocaleDateString('en-US', { weekday: 'long' }),
                        size: signup.size,
                        zone: signup.user.zone,
                        dish: signup.menuItem.dish.dishName
                    }
                })
        }
        return signupData.map(signup => {
            return {
                name: signup.user.fullName,
                day: new Date(parseFloat(signup.menuItem.menuDate)).toLocaleDateString('en-US', { weekday: 'long' }),
                size: signup.size,
                zone: signup.user.zone,
                dish: signup.menuItem.dish.dishName
            }
        })
    }

    const getGenerateButtonStyle = (menu) => {
        return isDateMoreThanXDaysFromToday(menu?.menuDate, DEADLINE_DAYS) && !showAll ?
            {
                backgroundColor: "#DDC798",
                color: "#FFFFFF",
                boxShadow: "inset 3px 4px 5px #A4800F",
                border: "none",
                cursor: "default"
            } : {}
    }

    const getActionForButton = () => {
        return isDateMoreThanXDaysFromToday(menu?.menuDate, DEADLINE_DAYS) && !showAll ? void 0 : fetchMenuList;
    }

    const filterGroup = (data, pickupGroup) => {
        if (pickupGroup) {
            const userIds = [];
            pickupGroup.users.forEach(user => userIds.push(user._id));
            console.log("Data", data);
            return userIds.length > 0 ? data.getSignupsForMenuItem
                .filter(signup => userIds.includes(signup.user._id)) : data.getSignupsForMenuItem;
        } else {
            return data.getSignupsForMenuItem;
        }
    }

    const getCountForSize = (data, pickupGroup, size) => {
        if (pickupGroup) {
            const userIds = [];
            pickupGroup.users.forEach(user => userIds.push(user._id));
            return data.getSignupsForMenuItem
                .filter(signup => userIds.includes(signup.user._id))
                .filter(signup => signup.size === size).length;
        }
        return data.getSignupsForMenuItem.filter(signup => signup.size === size).length;
    }

    return (
        <div id={"form" + menu._id} className="generateBtnForm">
            <div id={"button" + menu._id} className="generateBtn">
                <button id={menu._id} className="sizeButtons" onClick={fetchMenuList} >Generate</button>
            </div>
            <div id={"countAndLinkInfo" + menu._id}>
                {getPdfListData && isShowButtons(getPdfListData, menu._id) &&
                    <>
                        <p>Large:&nbsp;&nbsp;<b>{getCountForSize(getPdfListData, pickupGroup, 'Large')}</b></p>
                        <p>Small:&nbsp;&nbsp;<b>{getCountForSize(getPdfListData, pickupGroup, 'Small')}</b></p>
                    </>
                }
                {
                    !isDateMoreThanXDaysFromToday(menu?.menuDate, DEADLINE_DAYS) &&
                   <>
                        {
                            getPdfListData && isShowButtons(getPdfListData, menu._id) &&
                            filterGroup(getPdfListData, pickupGroup).length > 0 &&
                            <PDFDownloadLink
                                document={<ListPdfDocument
                                    signupsForMenuItem={filterGroup(getPdfListData, pickupGroup)}/>}
                                fileName={"List-" + fileNameGenerator(menu) + ".pdf"}
                                className="csvLink">List</PDFDownloadLink>
                        }
                        {
                            getPdfListData && isShowButtons(getPdfListData, menu._id) &&
                            filterGroup(getPdfListData, pickupGroup).length > 0 &&
                            <CSVLink
                                filename={"Labels-" + fileNameGenerator(menu) + ".csv"}
                                headers={getCsvHeaders()}
                                data={convertToCsvDataForAvery(getPdfListData.getSignupsForMenuItem, pickupGroup)}
                                className="csvLink">CSV</CSVLink>
                        }
                   </>
                }
            </div>
        </div>
    )
}

export default CountInfo;
