export const timeConverter = (timestamp, excludeDay) => {
    if (timestamp) {
        let epoch = parseFloat(timestamp);
        let dateFromDb = new Date(epoch);
        let addHoursForTimezone = dateFromDb.setHours(dateFromDb.getHours() + 6);
        if (excludeDay) {
            return new Date(addHoursForTimezone).toLocaleDateString('en-us', {
                month: "short",
                day: "numeric",
                year: "numeric"
            });
        }
        return new Date(addHoursForTimezone).toLocaleDateString('en-us', {
            weekday: "long",
            month: "short",
            day: "numeric"
        });
    }
}

export const isDateMoreThanXDaysFromToday = (date, days) => {
    let daysFromToday = new Date().setDate(new Date().getDate() + days);
    return new Date(parseFloat(date)) >= daysFromToday;
};

export const isDateXDaysBeforeToday = (date, days) => {
    let today = new Date();
    if (new Date(parseFloat(date)) === today) {
        return false;
    }
    if (new Date(parseFloat(date)) > today) {
        return false;
    }
    let daysFromToday = new Date(today.setDate(today.getDate() - days));
    return new Date(parseFloat(date)) >= daysFromToday;
};

export const showSignupForm = (event) => {
    hideAllForms();
    hideEditMessage();
    const signupForm = document.getElementById("form" + event.target.id);
    signupForm.style.visibility = "visible";
};

const hideAllForms = () => {
    let allSignupDivs = document.getElementsByClassName("signupForm");
    if (allSignupDivs.length > 0) {
        for (let i = 0; i < allSignupDivs.length; i++) {
            allSignupDivs[i].style.visibility = "hidden";
        }
    }
}

const hideEditMessage = () => {
    const editMessage = document.getElementsByClassName('cantEditMessage');
    if (editMessage.length > 0) {
        for (let i = 0; i < editMessage.length; i++) {
            editMessage[i].style.visibility = "hidden";
        }
    }
}

export const DEADLINE_DAYS = 3;
