import {useQuery} from "@apollo/client";
import {GET_PICKUP_GROUP, GET_PICKUP_ITEMS_BY_DATE} from "./gql/queries";
import SectionHeader from "../../components/SectionHeader";
import {timeConverter} from "../../utils/timeConverter";
import CountInfo from "../signups/components/CountInfo";
import React from "react";
import Auth from "../../utils/auth";
import {Navigate} from "react-router-dom";
import Nav from "../../components/Nav";
import GroupMembers from "./components/GroupMembers";

const Pickup = () => {
    const { data: pickupItemsData } = useQuery(GET_PICKUP_ITEMS_BY_DATE);
    const pickupItems = pickupItemsData?.pickupItemsByDate || [];

    const { data: pickupGroupData } = useQuery(GET_PICKUP_GROUP);
    const pickupGroup = pickupGroupData?.pickupGroup || {};

    return (
        <>
            {!Auth.loggedIn() && <Navigate to="/login" />}
            {!Auth.isFmbUser() && <Navigate to='/miqaats' />}
            <div className="navAndHeader">
                <Nav />
            </div>
            <h1>Pickup List</h1>
            <div className="mainContainer">
                {pickupGroup.users ?
                    <>
                        <SectionHeader title="Upcoming Dishes" className='sectionHeader' />
                        <div className="dishContainer">
                            {pickupItems && pickupItems.map((pickupItem) => (
                                <>
                                    <div id="cookLoginDishesRow" className="dishesRow">
                                        <p>{pickupItem.dish?.dishName}</p>
                                        <p>{timeConverter(pickupItem.menuDate)}</p>
                                        <CountInfo menu={pickupItem} pickupGroup={pickupGroup}/>
                                    </div>
                                    <hr className="dishRowLine" />
                                </>
                            ))}
                        </div>
                        <GroupMembers members={pickupGroup.users} />
                    </> :
                    <>
                        <p></p>
                        <p className="errMsg" style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>You are currently not part of a pickup group</p>
                        <p></p>
                    </>
                }
            </div>
        </>
    )
}

export default Pickup;
