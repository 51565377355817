import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import SectionHeader from "../../../components/SectionHeader";
import BarChart from "./BarChart";
import VerticalBarChart from "./VerticalBarChart";
import { useParams } from "react-router-dom";
import { GET_ALL_DISHES } from "../../dish/gql/queries";
import { GET_FEEDBACK_FOR_COOKS_MENU_ITEMS_BY_DISH } from "../gql/queries";
import {useMediaQuery} from "../../../utils/deviceChecker";

const FeedbackHistory = () => {

  const { cookId: cookParam } = useParams();

  const { data: getDishData } = useQuery(GET_ALL_DISHES);
  let dishes = getDishData?.dishes || [];
  const [getFeedbackForCookMenuItemsByDish, { data: feedbackForCookMenuItemsByDishData }] = useLazyQuery(GET_FEEDBACK_FOR_COOKS_MENU_ITEMS_BY_DISH,
    { variables: { cookId: cookParam, dishId: "" } }
  );
  const feedbackForCooksMenuItemsByDish = feedbackForCookMenuItemsByDishData?.feedbackForCooksMenuItemsByDish;

  const getDishHistory = async (event) => {
    await getFeedbackForCookMenuItemsByDish({
      variables: {
        cookId: cookParam,
        dishId: event.target.options[event.target.selectedIndex].id
      }
    });
  }

  const isDesktop = useMediaQuery("(min-width: 1258px)");

  const resultsMessage = (count) => {
    if (count === 0 || count === undefined) {
      return "You are the first to provide feedback!";
    } else {
      return "Showing average from " + count + " result" + (count === 1 ? "" : "s");
    }
  };

  return (
    <>
      <SectionHeader title="Feedback History" className='sectionHeader' />
      <div className="dishContainer">
        <div className="paymentHistoryDiv">
          <select className="paymentHistorySelect" onChange={getDishHistory}>
            <option disabled selected value="">
              -- Choose a Dish --
            </option>
            {dishes &&
              dishes.map((dish) => (
                <option key={dish._id} id={dish._id} value={JSON.stringify(dish)}>
                  {dish.dishName}
                </option>
              ))}
          </select>
        </div>
        {feedbackForCooksMenuItemsByDish && feedbackForCooksMenuItemsByDish.map((feedbackForCooksMenuItems) => (
          feedbackForCooksMenuItems[0]?.feedbackCount > 0 &&
          <>
            <div id="paymentHistoryRow" className="dishesRow">
              <div className="feedbackContainer" style={{ display: "flex" }}>
                <h4>{resultsMessage(feedbackForCooksMenuItems[0]?.feedbackCount)}</h4>
                {isDesktop ? (
                  <BarChart feedback={feedbackForCooksMenuItems[0]} />
                ) : (
                  <VerticalBarChart feedback={feedbackForCooksMenuItems[0]} />
                )}
                <div className="commentsSection" style={{ width: "100%", textAlign: "left" }}>
                  <h4>Comments: </h4>
                  <ul>
                    {feedbackForCooksMenuItems[0]?.feedbackComments?.
                      filter(comment => comment !== null && comment.trim() !== '').
                      map(feedbackComments => (<li>{feedbackComments}</li>))
                    }
                  </ul>
                </div>
              </div>
              <hr className="dishRowLine" />
            </div>
          </>
        ))}
      </div>
    </>
  )
}

export default FeedbackHistory