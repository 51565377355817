import React, { useEffect } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";
import { showSignupForm, timeConverter } from "../../utils/timeConverter";
import Nav from "../../components/Nav";
import DishPhotoCard from "../dish/components/DishPhotoCard";
import Auth from "../../utils/auth";
import { GET_OPEN_MENUS_ON_CLICK, GET_USER_SIGNUPS } from "./gql/queries";
import { CREATE_SIGNUP } from "./gql/mutations";
import { GET_ME } from "../user/gql/queries";

const OpenSignups = () => {

  const { loading: userSignupsLoading, data: getUserSignupsData, refetch: refetchUserSignups } = useQuery(GET_USER_SIGNUPS);

  let options = {
    variables: { number: "" },
    fetchPolicy: 'network-only'
  };
  const [getOpenMenusOnClick, { loading: openMenusOnClickLoading, data: getOpenMenusOnClickData, refetch: refetchOpenMenus }] =
    useLazyQuery(GET_OPEN_MENUS_ON_CLICK, options);
  let openMenusOnClickData = getOpenMenusOnClickData?.openMenusOnClick || [];

  const [createSignup] = useMutation(CREATE_SIGNUP);

  const { data } = useQuery(GET_ME);
  const me = data?.me;
  const isLimited = data?.me?.roles?.includes("LIMITED");

  useEffect(() => {
    getOpenMenusOnClick(
      { variables: { number: "200" } }
    );
  }, []);

  const fetchOpenMenus = async (event) => {
    let id = event.target.id;
    await getOpenMenusOnClick({
      variables: { number: id }
    });
  }

  const onSubmit = async (event) => {
    try {
      const { data } = await createSignup({
        variables: {
          menuItem: event.target.id,
          user: me.userId,
          size: event.target.value,
        },
      });
      showModal();
      refetchOpenMenus();
      refetchUserSignups();
    } catch (err) {
      console.error(err);
    }
  };

  const showModal = () => {
    const modal = document.querySelector('.modalParent')
    modal.style.visibility = 'visible'
  };

  const closeModal = () => {
    const modal = document.querySelector('.modalParent')
    modal.style.visibility = 'hidden'
  }

  return (
    <>
      {!Auth.loggedIn() && <Navigate to="/login" />}
      {!Auth.isFmbUser() && <Navigate to='/miqaats' />}
      <div className="navAndHeader">
        <Nav />
      </div>

      <div className="modalParent">
        <div className="modalSuccessful">
          <h3>Signup Successful!</h3>
          <button className='ok' onClick={closeModal}><p>Close</p></button>
        </div>
      </div>

      <div className="mainContainer">
        <h1>Open Menus</h1>
        <div className="categoriesTab">
          <div id="200" onClick={fetchOpenMenus}>
            <p id="200"> All menus</p>
          </div>
          <div id="7" onClick={fetchOpenMenus} >
            <p id="7">Next 7 days</p>
          </div>
          <div id="14" onClick={fetchOpenMenus}>
            <p id="14" >Next 14 days</p>
          </div>
          <div id="21" onClick={fetchOpenMenus}>
            <p id="21">Next 21 days</p>
          </div>
        </div>
        <div className="signupsContainer" id="menusOnClickContainer">
          {openMenusOnClickData.length === 0 ? <>
            <p></p> <p className="errMsg" style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>No menus available for signup</p><p></p></> :
            openMenusOnClickData.map((menu) => (
              <div key={menu._id} className="signup">
                <div className="signupsRow" key={menu._id}>
                  <div className="openSignupInfo">
                    <p>{menu.dish?.dishName}</p>
                    <p>{timeConverter(menu.menuDate)}</p>
                  </div>
                  <div className="addButtonAndPhoto">
                    <div className="addButton">
                      <p id={menu._id} className="addSignupPlus" onClick={showSignupForm}>+</p>
                    </div>
                    <div className="dishPhotoContainer">
                      <DishPhotoCard image={menu.dish?.dishPhoto} radius={"15"} border={"3px_solid_rgb:6B802A"} height={"130"} width={"130"} />
                    </div>
                  </div>
                </div>
                <div id={"form" + menu._id} className="signupForm">
                  <button id={menu._id} onClick={onSubmit} value="Small" className="sizeButtons">Small</button>
                  {!isLimited &&
                    <button id={menu._id} onClick={onSubmit} value="Large" className="sizeButtons">Large</button>
                  }
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default OpenSignups;
