import {gql} from "@apollo/client";

export const GET_ME = gql`
  query me {
    me {
      userId
      userFullName
      userZone
      memberFullName
      memberEmail
      memberIts
      memberHof
      roles
    }
  }
`;

export const GET_MEMBERS = gql`
  query members($userHofIts: String!) {
    members(userHofIts: $userHofIts) {
      _id
      email
      fullName
      its
    }
  }
`

export const GET_ALL_USERS = gql`
  query users {
    users {
       _id
        fullName
    }
  }
`

export const GET_PLEDGES = gql`
  query getPledge($userId: ID) {
    getPledge(userId: $userId) {
    _id
    amount
    period
    isPaid
    days
    }
  }
`

export const GET_MEMBER = gql`
  query getMember($memberId: ID!) {
    member(memberId: $memberId) {
      email
      fullName
      its
    }
  }
`

export const GET_USERS_IN_ZONE = gql`
query getUsersInZone($userZone: String!) {
  getUsersInZone(userZone: $userZone) {
    _id
    fullName
    zone
  }
}
`

export const NUMBER_OF_DAYS_AVAILED = gql`
  query numberOfDaysAvailed($userId: ID!) {
    numberOfDaysAvailed(userId: $userId) {
    days
    countedFrom
    }
  }`
