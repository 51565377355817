import React from "react";
import Auth from "../../../utils/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { Navigate } from "react-router-dom";
import SignupForm from "../../signups/components/SignupForm";
import { Link } from "react-router-dom";
import {LOGIN_USER} from "../gql/mutations";

const Login = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [login] = useMutation(LOGIN_USER);
    const [activeTab, setActiveTab] = useState("login");
    const [showSignUp, setShowSignUp] = useState(false);

    function handleSignUp() {
        setShowSignUp(true);
    }

    function handleLogin() {
        setShowSignUp(false);
    }

    const onSubmit = async (formData, event) => {
        event.preventDefault();
        try {
            const { data } = await login({
                variables: {
                    email: formData.email.toLowerCase(),
                    password: formData.password,
                },
            });
            Auth.login(data.login.token);
        } catch (err) {
            let errorMessage = document.querySelector('.errMsg')
            errorMessage.innerHTML = err.message
        }
    };

    const body = document.querySelector('body');
    body.style.margin = 0;

    const styles = {
        button: {
            border: "none",
            backgroundColor: "transparent",
            color: "#6B802A",
            padding: "10px",
            margin: "10px",
            width: "50%",
            cursor: "pointer",
            transition: "background-color 0.3s ease-in-out",
            minWidth: "150px",
        },
        activeButton: {
            borderRadius: "15px",
            color: "#6B802A",
            border: "2px solid #6B802A",
        },
    };

    return (
        <>
            {Auth.loggedIn() && <Navigate to="/openMenus" />}

            <div className='mainContainer'>
                <div className='logo' />
                <div className="signupLogin">
                    <button
                        style={{
                            ...styles.button,
                            ...(activeTab === "signup" && styles.activeButton),
                        }}
                        onClick={() => {
                            setActiveTab("signup");
                            handleSignUp();
                        }}
                    >
                        <h3>Sign Up</h3>
                    </button>

                    <button
                        style={{
                            ...styles.button,
                            ...(activeTab === "login" && styles.activeButton),
                        }}
                        onClick={() => {
                            setActiveTab("login");
                            handleLogin();
                        }}
                    >
                        <h3>Log In</h3>
                    </button>
                </div>

                {showSignUp ? (
                    <SignupForm />
                ) : (
                    <>
                        <div className='loginForm'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor='email'>Email</label>
                                <input {...register("email", { required: true })}
                                />
                                {errors.email && <p>This field is required</p>}

                                <label htmlFor='password'>Password</label>
                                <input type="password" {...register("password", { required: true })}
                                />
                                {errors.password && <p>This field is required</p>}

                                <div className="errorBox">
                                    <p className='errMsg'></p>
                                </div>

                                <div className='loginButton'>
                                    <button type="submit"><h3>Log In</h3></button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <Link to={`/reset`} style={{ textDecoration: "none", display: 'flex', justifyContent: 'center' }}>
                                <p className="forgotPasswordMsg">Forgot password</p>
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </>
    )
};

export default Login;