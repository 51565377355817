import {gql} from "@apollo/client";

export const MENU_PAID = gql`
    mutation menuPaid($menuId: ID $isPaid: Boolean) {
        menuPaid(menuId: $menuId isPaid: $isPaid) {
            _id
            isPaid
        }
    }
`

export const ADD_COST = gql`
    mutation addCost($menuId: ID $amount: Int) {
        addCost(menuId: $menuId amount: $amount) {
            _id
            amount
        }
    }
`

export const ADD_COOK = gql`
    mutation addCook($fullName: String!) {
        addCook(fullName: $fullName) {
            fullName
        }
    }
`

