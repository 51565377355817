import Auth from "../../utils/auth";
import {Link, Navigate} from "react-router-dom";
import Nav from "../../components/Nav";
import React from "react";
import {isDateXDaysBeforeToday, timeConverter} from "../../utils/timeConverter";
import {GET_USER_SIGNUPS_ON_CLICK} from "../signups/gql/queries";
import {useQuery} from "@apollo/client";
import SectionHeader from "../../components/SectionHeader";

const History = () => {

    const { loading: userSignupsLoading, data: getUserSignupsData } = useQuery(GET_USER_SIGNUPS_ON_CLICK, { variables: { number: "250" } });
    let userSignupsData = getUserSignupsData?.userSignupsOnClick || [];

    return (
        <>
            {!Auth.loggedIn() && <Navigate to="/login" />}
            {!Auth.isFmbUser() && <Navigate to='/miqaats' />}
            <div className="navAndHeader">
                <Nav />
            </div>
            <div className="mainContainer">
                <h1>History</h1>
                <SectionHeader title="Signups" className='sectionHeader' />
                <div className='dishContainer'>
                    {
                        userSignupsData && userSignupsData
                            .filter((signup) => isDateXDaysBeforeToday(signup.menuItem?.menuDate, 14))
                            .sort((a, b) => b.menuItem?.menuDate - a.menuItem?.menuDate)
                            .map((signup) => (
                                <>
                                    <div key={signup._id} className="signupHistoryRow">
                                        <p>{timeConverter(signup.menuItem?.menuDate)}</p>
                                        <p>{signup.menuItem?.dish?.dishName}</p>
                                        <p>{signup.size}</p>
                                        <Link to={`/feedback/${signup.menuItem._id}`} style={{ textDecoration: "none", display: 'flex', justifyContent: 'center' }}>
                                            <button className='submitButton feedbackButton'><p>Feedback</p></button>
                                        </Link>
                                    </div>
                                    <hr className="dishRowLine" />
                                </>
                            ))}
                </div>
            </div>
        </>);
}

export default History;
