import React, { useState, useEffect } from "react";
import { Image, Transformation } from "cloudinary-react";
import mockPhoto from '../../../assets/dishPhotoPlaceholder.jpg';


const DishPhotoCard = (props) => {
    const [imageId, setImageId] = useState("");

    useEffect(() => {
        if (props.image) {
            let newImage = `${props.image}.png`
            setImageId(newImage)
        }
    }, [props.image])

    return (
        <>
            {imageId ? (
                <Image cloudName={process.env.REACT_APP_CLOUD_NAME} publicId={imageId} alt={props.dish?.dishName} style={props.style}>
                    <Transformation width={props.width} height={props.height} gravity="auto" radius={props.radius} crop="fill" border={props.border}/>
                </Image>
            ) : (<img src={mockPhoto} className="dishPhoto" alt="Dish Photo Placeholder" />)
            }
        </>
    )
};

export default DishPhotoCard;