import React from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_COOKS_MENU_ITEMS_BY_DISH } from "../gql/queries";
import { GET_ALL_DISHES } from "../../dish/gql/queries";
import SectionHeader from "../../../components/SectionHeader";
import { timeConverter } from "../../../utils/timeConverter";

const PaymentHistory = () => {

    const { cookId: cookParam } = useParams();

    const { data: getDishData } = useQuery(GET_ALL_DISHES);
    let dishes = getDishData?.dishes || [];

    const getDishHistory = async (event) => {
        await getCookMenuItemsByDish({
            variables: {
                cookId: cookParam,
                dishId: event.target.options[event.target.selectedIndex].id
            }
        });
    }

    const [getCookMenuItemsByDish, { data: cookMenuItemsByDishData }] = useLazyQuery(GET_COOKS_MENU_ITEMS_BY_DISH,
        { variables: { cookId: cookParam, dishId: "" } }
    );
    const cookMenuItemsByDish = cookMenuItemsByDishData?.cookMenuItemsByDish;

    return (
        <>
            <SectionHeader title="Payment History" className='sectionHeader' />
            <div className="dishContainer">
                <div className="paymentHistoryDiv">
                    <select className="paymentHistorySelect" onChange={getDishHistory}>
                        <option disabled selected value="">
                            -- Choose a Dish --
                        </option>
                        {dishes && dishes.map((dish) => (
                            <option key={dish._id} id={dish._id} value={JSON.stringify(dish)}>
                                {dish.dishName}
                            </option>
                        ))}
                    </select>
                </div>
                {cookMenuItemsByDish && cookMenuItemsByDish.map((cookMenuItems) => (
                    <>
                        <div id="paymentHistoryRow" className="dishesRow">
                            <p>{cookMenuItems.dish.dishName}</p>
                            <p>{timeConverter(cookMenuItems.menuDate)}</p>
                            <p style={{ fontWeight: "bold" }}>${cookMenuItems.amount}</p>
                            {cookMenuItems.isPaid && (<p>Paid</p>)}
                            {!cookMenuItems.isPaid && (<p>Pending</p>)}
                        </div>
                        <hr className="dishRowLine" />
                    </>
                ))}
            </div>
        </>
    );
}

export default PaymentHistory;
