import React from "react";
import Nav from "../../components/Nav";
import DishList from "./components/DishList";
import UpcomingDishList from "./components/UpcomingDishList";
import Auth from '../../utils/auth';
import { Navigate } from 'react-router-dom';

const Dishes = () => {
    return (
        <>
            {!Auth.loggedIn() && <Navigate to="/login" />}
            {!Auth.isFmbAdmin() && <Navigate to='/profile' />}
            <div className="navAndHeader">
                <Nav />
            </div>
            <h1>Dishes</h1>
            <UpcomingDishList />
            <DishList />
        </>
    )
};

export default Dishes;