import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Hamburger from "./Hamburger";
import { Link } from "react-router-dom";
import Auth from '../utils/auth'
import {GET_ME} from "../pages/user/gql/queries";

export default function Nav() {

    const { data } = useQuery(GET_ME);
    const isFmbAdmin = data?.me?.roles?.includes("FMB_ADMIN");
    const isFmbUser = data?.me?.roles?.includes("FMB_USER") || data?.me?.roles?.includes("FMB_ADMIN");
    const isFeedbackAdmin = data?.me?.roles?.includes("FEEDBACK_ADMIN") || data?.me?.roles?.includes("FMB_ADMIN");

    const [hamburgerOpen, setHamburgerOpen] = useState(false);
    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    }

    const style = {
        navigationLink: {
            display: `${hamburgerOpen ? 'inline' : 'none'}`,
            backgroundColor: '#A4800F',
            height: '100vh',
            maxWidth: '350px',
            marginTop: '0px',
            paddingTop: '60px',
            position: 'fixed',
            zIndex: 2,
            paddingLeft: '0px'
        },
        navigationLinkAdmin: {
            display: `${hamburgerOpen ? 'inline' : 'none'}`,
        }
    }

    const handleLogout = () => {
        Auth.logout();
    };

    return (
        <>
            <div className="navigation">
                <ul style={style.navigationLink}>
                    {isFmbUser &&
                        <>
                            <li>
                                <Link to="/openMenus" style={{ textDecoration: "none", color: "white" }}><h3>Menus</h3></Link>
                            </li>
                            <li>
                                <Link to="/mySignups" style={{ textDecoration: "none", color: "white" }}><h3>Signups</h3></Link>
                            </li>
                            <li>
                                <Link to="/history" style={{ textDecoration: "none", color: "white" }}><h3>History</h3></Link>
                            </li>
                            <li>
                                <Link to="/pickup" style={{ textDecoration: "none", color: "white" }}><h3>Pickup</h3></Link>
                            </li>
                        </>
                    }
                    {isFmbAdmin &&
                        <li>
                            <Link to="/dishes" style={{ textDecoration: "none", color: "white" }}><h3>Dishes</h3></Link>
                        </li>
                    }
                    {isFeedbackAdmin &&
                        <li>
                            <Link to="/cooks" style={{ textDecoration: "none", color: "white" }}><h3>Cooks</h3></Link>
                        </li>
                    }
                    <li>
                        <Link to="/miqaats" style={{ textDecoration: "none", color: "white" }}><h3>Miqaats</h3></Link>
                    </li>
                    <li>
                        <Link to="/profile" style={{ textDecoration: "none", color: "white" }}><h3>Profile</h3></Link>
                    </li>
                    <li>
                        <Link onClick={handleLogout} style={{ textDecoration: "none", color: "white" }}><h3>Sign Out</h3></Link>
                    </li>
                </ul>
                <div className="hamburger" onClick={toggleHamburger}>
                    <Hamburger isOpen={hamburgerOpen} />
                </div>
                <h3 className="header" style={{ position: 'relative', left: '50px' }}>Faiz-ul Mawaid-il Burhaniyah</h3>
            </div>
            <div className="navigationDesktop">
                <h3 className="navHeader">Faiz-ul Mawaid-il Burhaniyah</h3>
                <div className="navLinks">
                    <ul>
                        {isFmbUser &&
                            <>
                                <li>
                                    <Link to="/openMenus" style={{ textDecoration: "none", color: "white" }}><h3>Menus</h3></Link>
                                </li>
                                <li>
                                    <Link to="/mySignups" style={{ textDecoration: "none", color: "white" }}><h3>Signups</h3></Link>
                                </li>
                                <li>
                                    <Link to="/history" style={{ textDecoration: "none", color: "white" }}><h3>History</h3></Link>
                                </li>
                                <li>
                                    <Link to="/pickup" style={{ textDecoration: "none", color: "white" }}><h3>Pickup</h3></Link>
                                </li>
                            </>
                        }
                        {isFmbAdmin &&
                            <li>
                                <Link to="/dishes" style={{ textDecoration: "none", color: "white" }}><h3>Dishes</h3></Link>
                            </li>
                        }
                        {isFeedbackAdmin &&
                            <li>
                                <Link to="/cooks" style={{ textDecoration: "none", color: "white" }}><h3>Cooks</h3></Link>
                            </li>
                        }
                        <li>
                            <Link to="/miqaats" style={{ textDecoration: "none", color: "white" }}><h3>Miqaats</h3></Link>
                        </li>
                        <li>
                            <Link to="/profile" style={{ textDecoration: "none", color: "white" }}><h3>Profile</h3></Link>
                        </li>
                        <li>
                            <Link onClick={handleLogout} style={{ textDecoration: "none", color: "white" }}><h3>Sign Out</h3></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}