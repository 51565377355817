import {gql} from "@apollo/client";

export const CREATE_SIGNUP = gql`
    mutation createSignup($user: String $menuItem: String $size: String) {
        createSignup(user: $user menuItem: $menuItem size: $size) {
            user {
                _id
            }
            menuItem {
                _id
            }
            size
        }
    }
`

export const CREATE_MENU = gql`
    mutation createMenu($dish: String $cook: String $amount: Int $isPaid: Boolean $menuDate: String) {
        createMenu(dish: $dish cook: $cook amount: $amount isPaid: $isPaid menuDate: $menuDate)  {
            dish {
                _id
            }
            cook {
                _id
            }
            amount
            isPaid
        }
    }
`

export const DELETE_SIGNUP = gql`
    mutation deleteSignup($signupId: ID) {
        deleteSignup(signupId: $signupId) {
            menuItem {
                _id
            }
        }
    }
`
