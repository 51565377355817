const Category = {
  Dal: 'Dal',
  ChickenGravy: 'Chicken Gravy',
  Rice: 'Rice',
  MuttonGravy: 'Mutton Gravy',
  Vegetarian: 'Vegetarian',
  Side: 'Side',
  Kebab: 'Kebab',
  NonIndian: 'Non-Indian'
}

export default Category;