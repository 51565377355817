import React, { useMemo } from "react";
import * as d3 from "d3";

const BarChart = (props) => {
    const width = 1000;
    const height = 400;
    const data = [
        { name: "Spice", value: props.feedback?.spicyRating_Avg },
        { name: "Oil", value: props.feedback?.oilyRating_Avg },
        { name: "Dryness", value: props.feedback?.dryRating_Avg },
        { name: "Quantity", value: props.feedback?.quantityRating_Avg },
        { name: "Frequency", value: props.feedback?.frequencyRating_Avg }
    ]

    const MARGIN = { top: 30, right: 30, bottom: 30, left: 30 };
    const BAR_PADDING = 0.3;

    // bounds = area inside the graph axis = calculated by substracting the margins
    const boundsWidth = width - MARGIN.right - MARGIN.left;
    const boundsHeight = height - MARGIN.top - MARGIN.bottom;

    // Y axis is for groups since the barplot is horizontal
    const groups = data.map((d) => d.name);
    const yScale = useMemo(() => {
        return d3
            .scaleBand()
            .domain(groups)
            .range([0, boundsHeight])
            .padding(BAR_PADDING);
    }, [data, height]);

    // X axis
    const xScale = useMemo(() => {
        const [min, max] = d3.extent(data.map((d) => d.value));
        return d3
            .scaleLinear()
            .domain([0, max || 10])
            .range([0, boundsWidth]);
    }, [data, width]);

    // Build the shapes
    const allShapes = data.map((d, i) => {
        const y = yScale(d.name);
        if (y === undefined) {
            return null;
        }

        return (
            <g key={i}>
                <rect
                    x={xScale(0)}
                    y={yScale(d.name)}
                    width={xScale(d.value)}
                    height={yScale.bandwidth()}
                    opacity={0.7}
                    stroke="#D9AA16"
                    fill="#D9AA16"
                    fillOpacity={1}
                    strokeWidth={1}
                    rx={1}
                />
                <text
                    x={xScale(d.value) - 7}
                    y={y + yScale.bandwidth() / 2}
                    textAnchor="end"
                    alignmentBaseline="central"
                    fontSize={12}
                    opacity={xScale(d.value) > 90 ? 1 : 0} // hide label if bar is not wide enough
                >
                    {d.value}
                </text>
                <text
                    x={xScale(0) + 7}
                    y={y + yScale.bandwidth() / 2}
                    textAnchor="start"
                    alignmentBaseline="central"
                    fontSize={12}
                >
                    {d.name}
                </text>
            </g>
        );
    });

    const grid = xScale
        .ticks(5)
        .slice(1)
        .map((value, i) => (
            <g key={i}>
                <line
                    x1={xScale(value)}
                    x2={xScale(value)}
                    y1={0}
                    y2={boundsHeight}
                    stroke="#808080"
                    opacity={0.2}
                />
                <text
                    x={xScale(value)}
                    y={boundsHeight + 10}
                    textAnchor="middle"
                    alignmentBaseline="central"
                    fontSize={9}
                    stroke="#808080"
                    opacity={0.8}
                >
                    {value}
                </text>
            </g>
        ));

    return (
        <div>
            <svg width={width} height={height}>
                <g
                    width={boundsWidth}
                    height={boundsHeight}
                    transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
                >
                    {grid}
                    {allShapes}
                </g>
            </svg>
        </div>
    );
};


export default BarChart;