import React, { useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  isDateMoreThanXDaysFromToday,
  showSignupForm,
  isDateXDaysBeforeToday,
  timeConverter, DEADLINE_DAYS
} from "../../utils/timeConverter";
import Nav from "../../components/Nav";
import DishPhotoCard from "../dish/components/DishPhotoCard";
import CantChangeMessage from "./components/CantChangeMessage";
import Auth from "../../utils/auth";
import { Navigate } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader";
import { GET_OPEN_MENUS_ON_CLICK, GET_USER_SIGNUPS_ON_CLICK } from "./gql/queries";
import { CREATE_SIGNUP, DELETE_SIGNUP } from "./gql/mutations";
import { GET_ME } from "../user/gql/queries";

const UserSignups = () => {
  let options = {
    variables: { number: "" },
    fetchPolicy: 'network-only'
  };

  const [getUserSignupsOnClick, { loading: userSignupsLoading, data: getUserSignupsData, refetch: refetchUserSignups }] = useLazyQuery(GET_USER_SIGNUPS_ON_CLICK, options);
  let userSignupsData = getUserSignupsData?.userSignupsOnClick || [];

  const { loading: openMenusLoading, data: getOpenMenusData, refetch: refetchOpenMenus } = useQuery(GET_OPEN_MENUS_ON_CLICK, { variables: { number: "250" } });

  useEffect(() => {
    getUserSignupsOnClick(
      { variables: { number: "250" } }
    );
  }, []);

  const [deleteSignup] = useMutation(DELETE_SIGNUP);

  const onDeleteClick = async (event) => {
    try {
      const { data } = await deleteSignup({
        variables: {
          signupId: event.target.id,
        },
      });
      refetchUserSignups();
      refetchOpenMenus();
    } catch (err) {
      console.error(err);
    }
  };

  const [createSignup] = useMutation(CREATE_SIGNUP);

  const { data } = useQuery(GET_ME);
  const me = data?.me;
  const isLimited = data?.me?.roles?.includes("LIMITED");

  const onSubmit = async (event) => {
    try {
      const { data } = await createSignup({
        variables: {
          menuItem: event.target.id,
          user: me.userId,
          size: event.target.value,
        },
      });
      refetchUserSignups();
      refetchOpenMenus();
    } catch (err) {
      console.error(err);
    }
  };

  const fetchUserSignups = async (event) => {
    let id = event.target.id;
    await getUserSignupsOnClick({
      variables: { number: id }
    });
  }

  return (
    <>
      {!Auth.loggedIn() && <Navigate to="/login" />}
      {!Auth.isFmbUser() && <Navigate to='/miqaats' />}
      <div className="navAndHeader">
        <Nav />
      </div>
      <div className="mainContainer">
        <h1>Signups</h1>
        <div className="categoriesTab">
          <div id="250" onClick={fetchUserSignups}>
            <p id="250"> All signups</p>
          </div>
          <div id="7" onClick={fetchUserSignups} >
            <p id="7">Next 7 days</p>
          </div>
          <div id="14" onClick={fetchUserSignups}>
            <p id="14" >Next 14 days</p>
          </div>
          <div id="21" onClick={fetchUserSignups}>
            <p id="21">Next 21 days</p>
          </div>
        </div>

        <div className="signupsContainer">
          {
            userSignupsData && userSignupsData
              .filter((signup) => isDateMoreThanXDaysFromToday(signup.menuItem?.menuDate, -1))
              .sort((a, b) => a.menuItem?.menuDate - b.menuItem?.menuDate)
              .map((signup) => (
                <div key={signup._id} className="userSignup">
                  <div className="signupsRow">
                    <div className="yourSignupInfo">
                      <p>{timeConverter(signup.menuItem.menuDate)}</p>
                      <p>{signup.menuItem?.dish?.dishName}</p>
                      <p>{signup.size}</p>
                    </div>
                    <div className="buttonAndPhoto">
                      {isDateMoreThanXDaysFromToday(signup.menuItem.menuDate, DEADLINE_DAYS) && (
                        <div className="editButton">
                          <p id={signup.menuItem._id} onClick={showSignupForm} className="editButtonText">Edit</p>
                        </div>
                      )}
                      {!isDateMoreThanXDaysFromToday(signup.menuItem.menuDate, DEADLINE_DAYS) && (
                        <div className="editButton" style={{ backgroundColor: "#DDC798", color: "#FFFFFF", boxShadow: "inset 3px 4px 5px #A4800F", cursor: "default" }}>
                          <p id={signup.menuItem._id} className="editButtonText">Edit</p>
                        </div>
                      )}
                      {isDateMoreThanXDaysFromToday(signup.menuItem.menuDate, DEADLINE_DAYS) && (
                        <div className="cancelButton">
                          <p id={signup._id} onClick={onDeleteClick} className="cancelButtonText">Cancel</p>
                        </div>
                      )}
                      {!isDateMoreThanXDaysFromToday(signup.menuItem.menuDate, DEADLINE_DAYS) && (
                        <div className="cancelButton" style={{ backgroundColor: "#DDC798", color: "#FFFFFF", boxShadow: "inset 3px 4px 5px #A4800F", cursor: "default" }}>
                          <p id={signup._id} className="cancelButtonText">Cancel</p>
                        </div>
                      )}
                      <div className="dishPhotoContainer">
                        <DishPhotoCard image={signup.menuItem?.dish?.dishPhoto} radius={"15"} border={"3px_solid_rgb:6B802A"} height={"130"} width={"130"} />
                      </div>
                    </div>
                    <CantChangeMessage id={"edit" + signup.menuItem._id} className={"cantEditMessage"} style={{ visibility: "hidden" }} message={"You can't edit now"} />
                    <CantChangeMessage id={"cancel" + signup._id} className={"cantCancelMessage"} style={{ visibility: "hidden" }} message={"You can't cancel now"} />
                  </div>
                  <div id={"form" + signup.menuItem._id} className="signupForm">
                    <button id={signup.menuItem._id} onClick={onSubmit} value="Small" className="sizeButtons">Small</button>
                    {!isLimited &&
                      <button id={signup.menuItem._id} onClick={onSubmit} value="Large" className="sizeButtons">Large</button>
                    }
                  </div>
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default UserSignups;
