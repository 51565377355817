import { gql } from "@apollo/client";

export const CREATE_MIQAAT = gql`
    mutation createMiqaat($miqaatId: ID, $title: String, $hosts: [String], $date: String, $hijriDate: String, $image: String, $description: String, $time: String, $program: String) {
        createMiqaat(miqaatId: $miqaatId, title: $title, hosts: $hosts, date: $date, hijriDate: $hijriDate, image: $image, description: $description, time: $time, program: $program) {
            _id
            title
            hosts {
                _id
            }
            date
            hijriDate
            image
            description
            time
            program
        }
    }
`

export const CREATE_RSVP = gql`
    mutation createRsvp($rsvpId: ID, $user: String, $miqaat: String, $adults: Int, $children: Int) {
        createRsvp(rsvpId: $rsvpId, user: $user, miqaat: $miqaat, adults: $adults, children: $children) {
            adults
            children
        }
    }
`
