import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client'
import { GET_ALL_COOKS } from './gql/queries'
import CookForm from './components/CookForm'
import Auth from '../../utils/auth';
import { Navigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Nav from "../../components/Nav";

const Cooks = () => {
  const [cooks, setCook] = useState([]);

  const { loading: cookLoading, data: getCookData } = useQuery(GET_ALL_COOKS);
  let cookData = getCookData?.cooks || [];

  const showAddCook = () => {
    const cookForm = document.querySelector(".cookForm");
    cookForm.style.visibility = "visible";
  };

  useEffect(() => {
    if (!cookLoading) {
      setCook(cookData);
    }
  }, [cookData]);

  return (
    <>
      {!Auth.loggedIn() && <Navigate to="/login" />}
      {!Auth.isFeedbackAdmin() && <Navigate to='/profile' />}
      <div className="navAndHeader">
        <Nav />
      </div>
      <div className='mainContainer'>
        <h1>Cooks</h1>
        <div className="cookTiles">
          {cooks &&
            cooks.map((cook) => (
              <Link to={`/cook/${cook._id}`} style={{ textDecoration: "none" }}>
                <div className="cookTile" key={cook._id}>
                  <p>{cook.fullName}</p>
                </div>
              </Link>
            ))}
          <div className="cookTile" onClick={showAddCook}>
            <p id="addCookPlus">
              +
            </p>
          </div>
        </div>
        <div className="cookFormWrapperDiv">
          <CookForm cooks={cooks} setCook={setCook} />
        </div>
      </div>
    </>
  )
}

export default Cooks;