import {gql} from "@apollo/client";

export const GET_PICKUP_ITEMS_BY_DATE = gql`
  query getPickupItemsByDate {
    pickupItemsByDate
     {
      _id
      dish {
        _id
        dishName
        dishPhoto
      }
      menuDate
    }
  }
`

export const GET_PICKUP_GROUP = gql`
  query getPickupGroup {
    pickupGroup {
      _id
      name
      users {
       _id
       fullName      
      }
    }
  }
`
