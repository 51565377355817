import {gql} from "@apollo/client";

export const PROVIDE_FEEDBACK = gql`
    mutation provideFeedback($menuItem: String, $user: String, $spicyRating: Int, $oilyRating: Int, $quantityRating: Int, $dryRating: Int, $frequencyRating: Int, $feedbackComments: String) {
        provideFeedback(menuItem: $menuItem, user: $user, spicyRating: $spicyRating, oilyRating: $oilyRating, quantityRating: $quantityRating, dryRating: $dryRating, frequencyRating: $frequencyRating, feedbackComments: $feedbackComments) {
            menuItem {
                _id
            }
            user {
                _id
            }
            spicyRating
            oilyRating
            quantityRating
            dryRating
            frequencyRating
            feedbackComments
        }
    }
`
