import React from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_MIQAATS } from "./gql/queries";
import Nav from "../../components/Nav";
import Auth from "../../utils/auth";
import { Navigate } from "react-router-dom";
import CreateMiqaat from "./components/CreateMiqaat";
import UserMiqaat from "./components/UserMiqaat";
import UserRsvp from "./components/UserRsvp";
import MiqaatCard from "./components/MiqaatCard";
import { useMediaQuery } from "../../utils/deviceChecker";

const Miqaat = () => {

    const { data: miqaatData } = useQuery(GET_ALL_MIQAATS);
    let miqaats = miqaatData?.miqaats || [];
    const isDesktop = useMediaQuery("(min-width: 1258px)");

    return (
        <>
            {!Auth.loggedIn() && <Navigate to="/login" />}
            <div className="navAndHeader">
                <Nav />
            </div>

            <div className="mainContainer">
                <h1>Upcoming Miqaats</h1>
                <div className="miqaatContainer">
                    {miqaats && miqaats.map((miqaat) => (
                        <MiqaatCard miqaat={miqaat} />
                    ))}
                </div>
                <UserRsvp />
                <UserMiqaat />
                {Auth.isMiqaatAdmin() && Auth.isFmbAdmin() && isDesktop && <CreateMiqaat />}
            </div>
        </>
    )
};

export default Miqaat;
