import React from "react";
import { useQuery } from "@apollo/client";
import Nav from "../../components/Nav";
import {
    GET_ME,
    GET_MEMBERS,
    GET_PLEDGES,
    GET_USERS_IN_ZONE,
    NUMBER_OF_DAYS_AVAILED,
} from "./gql/queries";
import { timeConverter } from "../../utils/timeConverter";
import Auth from "../../utils/auth";
import { Navigate } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader";
import { Link } from "react-router-dom";
import {useMediaQuery} from "../../utils/deviceChecker";

const Profile = () => {
    const { data } = useQuery(GET_ME);
    const me = data?.me;
    const userHofIts = me?.memberHof;
    const userZone = me?.userZone;
    const userId = me?.userId

    const { data: memberData } = useQuery(GET_MEMBERS, {
        variables: { userHofIts: userHofIts },
    });
    const members = memberData?.members || [];

    const { data: zoneData } = useQuery(GET_USERS_IN_ZONE, {
        variables: { userZone: userZone },
    });
    const zoneMembers = zoneData?.getUsersInZone || [];

    const { data: availedData } = useQuery(
        NUMBER_OF_DAYS_AVAILED, {
        variables: { userId: userId },
    });
    let numDaysAvailed = availedData?.numberOfDaysAvailed.days || 0;
    let countedFrom = availedData?.numberOfDaysAvailed.countedFrom || "";

    const { data: pledgeData } = useQuery(GET_PLEDGES, {
        variables: { userId: userId },
    });
    const pledges = pledgeData?.getPledge || [];

    const isDesktop = useMediaQuery("(min-width: 1258px)");

    const printPledgeStatus = (pledge) => {
        if (pledge.amount === 0) {
            return (<p><b>-</b></p>)
        }
        if (pledge.isPaid) {
            return (<p><b>Adaa</b></p>)
        } else {
            return (<p><b>Pending</b></p>)
        }
    }

    return (
        <>
            {!Auth.loggedIn() && <Navigate to="/login" />}
            <div className="navAndHeader">
                <Nav />
            </div>
            <div className="mainContainer">
                <h1>Profile Information</h1>
                <SectionHeader title="HOF Details" className="sectionHeader" />
                <div className="dishContainer">
                    <div id="cookDishesRow" className="dishesRow">
                        <p><b>Name:</b> {me?.userFullName}</p>
                        <p><b>ITS:</b> {me?.memberHof}</p>
                    </div>
                </div>
                <SectionHeader title="Registered family members" className="sectionHeader" />
                <div className="dishContainer">
                    {members &&
                        members.map((member) => (
                            <div className="signupHistoryRow">
                                <p>{member.fullName}</p>
                                <p>{member.its}</p>
                                <p>{member.email}</p>
                                <Link
                                    to={`/update/${member._id}`}
                                    id={member._id}
                                    style={{
                                        textDecoration: "none",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <button className="submitButton feedbackButton">
                                        <p>Update</p>
                                    </button>
                                </Link>
                            </div>
                        ))}
                </div>
                {isDesktop ? (
                    <div className="zoneAndStatsContainer">
                        <div className="zoneContainer">
                            <SectionHeader title={`Zone ${userZone}`} className="zsSectionHeader" />
                            <div id="zoneMembersRow" className="dishesRow">
                                {zoneMembers && zoneMembers.map((member) => (
                                    <p>{member.fullName}</p>
                                ))}
                            </div>
                        </div>
                        <div className="statsContainer">
                            <div id="thaaliStats" className="thaaliAndTakhmeenStats">
                                <SectionHeader title="Thaali Stats" className="zsSectionHeader" />
                                <div className="stats">
                                    <p>Days availed since {timeConverter(countedFrom, true)}:<b> {numDaysAvailed} </b></p>
                                </div>
                            </div>
                            <div className="thaaliAndTakhmeenStats">
                                <SectionHeader title="Takhmeen History" className="zsSectionHeader" />
                                <div className="stats">
                                    {pledges.length === 0 ? (<p>No takhmeen history available</p>) :
                                        pledges.map((pledge) => (
                                            <div className="takhmeenStats">
                                                <p>{pledge.period}</p>
                                                <p>{pledge.days} day{pledge.days === 1 ? '' : 's'} (${pledge.amount})</p>
                                                {printPledgeStatus(pledge)}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (<>
                    <SectionHeader title={`Zone ${userZone}`} className="zsSectionHeader" />
                    {zoneMembers &&
                        zoneMembers.map((member) => (
                            <div id="zoneMembersRow" className="dishesRow">
                                <p>{member.fullName}</p>
                            </div>
                        ))}
                    <SectionHeader title="Thaali Stats" className="zsSectionHeader" />
                        <div className="dishesRow">
                            <p>Days availed since {timeConverter(countedFrom, true)}:<b> {numDaysAvailed} </b></p>
                        </div>
                    <SectionHeader title="Takhmeen History" className="zsSectionHeader" />
                    {pledges.length === 0 ?
                        (<div className="dishesRow">
                            <p>No takhmeen history available</p>
                        </div>) :
                        pledges.map((pledge) => (
                            <div className="dishesRow">
                                <p>{pledge.period}</p>
                                <p>{pledge.days} day{pledge.days === 1 ? '' : 's'} (${pledge.amount})</p>
                                {printPledgeStatus(pledge)}
                            </div>
                        ))}
                </>
                )}
            </div >
        </>
    );
};

export default Profile;
