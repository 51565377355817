import decode from 'jwt-decode';

class AuthService {

    loggedIn() {
        const token = this.getToken();
        return token && !this.isTokenExpired(token);
    }

    isFmbAdmin() {
        const token = this.getToken();
        return token && decode(token).data.roles.includes("FMB_ADMIN");
    }

    isFeedbackAdmin() {
        const token = this.getToken();
        return token && (decode(token).data.roles.includes("FEEDBACK_ADMIN") ||
            decode(token).data.roles.includes("FMB_ADMIN"));
    }

    isMiqaatAdmin() {
        const token = this.getToken();
        return token && decode(token).data.roles.includes("MIQAAT_ADMIN");
    }

    isFmbUser() {
        const token = this.getToken();
        return token && (decode(token).data.roles.includes("FMB_USER") ||
            decode(token).data.roles.includes("FMB_ADMIN"));
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            } else return false;
        } catch (err) {
            return false;
        }
    }

    getToken() {
        return localStorage.getItem('id_token');
    }

    login(idToken) {
        localStorage.setItem('id_token', idToken);
    }

    logout() {
        localStorage.removeItem('id_token');
        window.location.assign('/');
    }
}

export default new AuthService();