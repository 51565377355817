import React from "react";

const GroupMembers = (props) => {

    return (
        <>
            <h1>Members</h1>
            <div className="cookTiles">
            {props.members &&
                props.members.map(member => (
                    <div className="cookTile">
                        <p>{member.fullName}</p>
                    </div>
                ))}
            </div>
        </>
    );
}

export default GroupMembers;
