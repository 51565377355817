import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { CREATE_RSVP } from "../gql/mutations";
import { GET_ME } from "../../user/gql/queries";
import { GET_RSVP_FOR_USER, GET_USER_RSVP_FOR_MIQAAT } from "../gql/queries";

const CreateRsvpForm = (props) => {

    const { refetch: refetchUserRsvps } = useQuery(GET_RSVP_FOR_USER);

    const { data: rsvpData } = useQuery(GET_USER_RSVP_FOR_MIQAAT, {
        variables: { miqaatId: props.miqaat._id },
    });

    const existingRsvp = rsvpData?.usersRsvpsForMiqaat.find(
        (rsvp) => rsvp.miqaat._id === props.miqaat._id
    );

    const isLocked = (date) => {
        return new Date() >= new Date(new Date(parseFloat(date)));
    }

    const [createRsvp] = useMutation(CREATE_RSVP);
    const { register, handleSubmit } = useForm();

    const { data } = useQuery(GET_ME);
    const me = data?.me;
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8];

    const onSubmit = async (formData, event) => {
        try {
            const { data } = await createRsvp({
                variables: {
                    miqaat: event.target.id,
                    user: me.userId,
                    adults: parseInt(formData.adults),
                    children: parseInt(formData.children)
                },
            });
            props.showModal();
            refetchUserRsvps();
        } catch (err) {
            console.error(err);
        }
    }

    return (<>
        {
            rsvpData &&
            <form id={props.miqaat._id} onSubmit={handleSubmit(onSubmit)} className="createRsvpForm" >
                <div>
                    <select {...register("adults", { required: true })}
                            defaultValue={existingRsvp?.adults}
                            disabled={isLocked(props.miqaat.date)}
                            placeholder="Adults">
                        <option disabled selected value=''>Adults</option>
                        {numbers.map((number) => (
                            <option key={number} value={number}>{number}</option>
                        ))}
                    </select>
                    <select {...register("children", { required: true })}
                            defaultValue={existingRsvp?.children}
                            disabled={isLocked(props.miqaat.date)}
                            placeholder="Children">
                        <option disabled selected value=''>Children (5+)</option>
                        {numbers.map((number) => (
                            <option key={number} value={number}>{number}</option>
                        ))}
                    </select>
                </div>
                <input type="submit" id={props.miqaat._id} disabled={isLocked(props.miqaat.date)} value='Submit' />
            </form>
        }

    </>
    )
};

export default CreateRsvpForm;
