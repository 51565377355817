import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_COOKS_UNPAID_MENUS } from "../gql/queries";
import { MENU_PAID } from "../gql/mutations";
import SectionHeader from "../../../components/SectionHeader";
import { timeConverter } from "../../../utils/timeConverter";
import AddCostForm from "./AddCostForm";

const PendingCookPayments = () => {

    const { cookId: cookParam } = useParams();

    const [unpaidMenus, setUnpaidMenus] = useState([]);

    const { data: cooksUnpaidMenusData } = useQuery(GET_COOKS_UNPAID_MENUS, { variables: { cookId: cookParam } });
    const cooksUnpaidMenus = cooksUnpaidMenusData?.getCooksUnpaidMenus;

    useEffect(() => {
        if (cooksUnpaidMenus) {
            setUnpaidMenus(cooksUnpaidMenus);
        }
    }, [cooksUnpaidMenus]);

    const [menuPaid] = useMutation(MENU_PAID);
    const menuPaidButton = async (event) => {
        try {
            await menuPaid({
                variables: {
                    menuId: event.target.id,
                    isPaid: true,
                },
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <SectionHeader title="Pending Payments" className='sectionHeader' />
            <div className="dishContainer">
                {unpaidMenus &&
                    unpaidMenus.map(cookMenu => (
                        <>
                            <div className="dishesRow">
                                <p>{cookMenu.dish?.dishName}</p>
                                <p>{timeConverter(cookMenu.menuDate)}</p>
                                {cookMenu.amount && (
                                    <div className="amountAndPaidButton">
                                        <p style={{ fontWeight: "bold" }}>
                                            Total: ${cookMenu.amount}
                                        </p>
                                        <button className="menuPaidButton" onClick={menuPaidButton}>
                                            <p id={cookMenu._id}>Paid</p>
                                        </button>
                                    </div>
                                )}
                                {!cookMenu.amount && (
                                    <AddCostForm id={cookMenu._id} unpaidMenus={unpaidMenus} setUnpaidMenus={setUnpaidMenus} />
                                )}
                            </div>
                            <hr className="dishRowLine" />
                        </>
                    ))}
            </div>
        </>
    )
}

export default PendingCookPayments;
